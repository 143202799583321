<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title {{ device.id ? 'Edit device' : 'Create device' }}
			.widget-body
				form(@submit.prevent="submit")
					.row
						.col-sm-8
							.form-group
								label Name
								input.form-control(type="text", v-model="server.name")
						.col-sm-4
							.form-group
								label Status
								select.form-control(v-model.number="server.status")
									option(:value="0") Inactive
									option(:value="1") Active
					.row
						.col-sm-6
							.form-group
								label IP
								input.form-control(type="text", v-model="server.ip")
						.col-sm-6
							.form-group
								label PSK
								input.form-control(type="text", v-model="server.psk")
								
					
					.buttons.text-right
						router-link.btn.btn-default(:to="{name:'device-list'}")
							i.la.la-angle-left
							| Back
						| &nbsp;
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';

export default {
  name: 'DeviceForm',
  async beforeRouteEnter(to, from, next) {
    let server = {};
    if (to.name === 'device-edit') {
      let entityId = +to.params.id;
      try {
        let resp = await Vue.ovReq.get('device/get/' + entityId);
        server = resp.entity;
      } catch (e) {
        return next(false);
      }
    } else {
      server = {
        id: 0,
        name: '',
        ip: '',
        status: 0,
      };
    }
    next((vm) => {
      vm.server = server;
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      server: {},
    };
  },
  methods: {
    async submit() {
      this.busy = true;
      console.log('submit');
      let data = Vue.util.extend({}, this.server);
      try {
        let resp = await this.$ovReq.post('device/save', data);
        this.busy = false;
        console.log(resp);
        Vue.notify({
          group: 'default',
          title: 'Success',
          type: 'success',
          text: 'Device has been saved',
        });
        this.$router.push({ name: 'device-list' });
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },
  },
};
</script>
